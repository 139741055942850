import React, { useEffect, useState } from 'react';
import SpotifyWebApi from 'spotify-web-api-js';
import { LogIn, Music2 } from 'lucide-react';
import { PlaylistCard } from './components/PlaylistCard';
import { TrackList } from './components/TrackList';
import type { SpotifyPlaylist, SpotifyTrack } from './types/spotify';

const SPOTIFY_CLIENT_ID = '3e0359714f2c4a13b17971e5a157ae0c';
const REDIRECT_URI = window.location.origin;
const SCOPES = ['playlist-read-private', 'playlist-read-collaborative', 'playlist-modify-public', 'playlist-modify-private'];

const spotify = new SpotifyWebApi();

function App() {
  const [token, setToken] = useState<string | null>(null);
  const [playlists, setPlaylists] = useState<SpotifyPlaylist[]>([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState<string | null>(null);
  const [tracks, setTracks] = useState<SpotifyTrack[]>([]);
  const [isLoadingTracks, setIsLoadingTracks] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<string>('');

  useEffect(() => {
    const hash = window.location.hash
      .substring(1)
      .split('&')
      .reduce((acc: Record<string, string>, item) => {
        const [key, value] = item.split('=');
        acc[key] = decodeURIComponent(value);
        return acc;
      }, {});

    if (hash.access_token) {
      setToken(hash.access_token);
      spotify.setAccessToken(hash.access_token);
      window.location.hash = '';
    }
  }, []);

  useEffect(() => {
    if (token) {
      spotify.getMe().then(user => {
        setCurrentUserId(user.id);
      });
      
      spotify.getUserPlaylists().then((data) => {
        setPlaylists(data.items);
      });
    }
  }, [token]);

  useEffect(() => {
    if (selectedPlaylist) {
      setIsLoadingTracks(true);
      spotify
        .getPlaylistTracks(selectedPlaylist)
        .then((data) => {
          setTracks(data.items.map((item) => item.track));
        })
        .finally(() => {
          setIsLoadingTracks(false);
        });
    }
  }, [selectedPlaylist]);

  const handleDeleteTracks = async (trackUris: string[]) => {
    if (!selectedPlaylist) return;

    await spotify.removeTracksFromPlaylist(selectedPlaylist, trackUris);
    const updatedTracks = tracks.filter(track => !trackUris.includes(track.uri));
    setTracks(updatedTracks);

    // Update playlist track count
    const playlistIndex = playlists.findIndex(p => p.id === selectedPlaylist);
    if (playlistIndex !== -1) {
      const updatedPlaylists = [...playlists];
      updatedPlaylists[playlistIndex] = {
        ...updatedPlaylists[playlistIndex],
        tracks: {
          total: updatedPlaylists[playlistIndex].tracks.total - trackUris.length
        }
      };
      setPlaylists(updatedPlaylists);
    }
  };

  const login = () => {
    const authEndpoint = 'https://accounts.spotify.com/authorize';
    const queryParams = new URLSearchParams({
      client_id: SPOTIFY_CLIENT_ID,
      redirect_uri: REDIRECT_URI,
      scope: SCOPES.join(' '),
      response_type: 'token',
    });

    window.location.href = `${authEndpoint}?${queryParams.toString()}`;
  };

  if (!token) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-green-400 to-green-600 flex items-center justify-center">
        <div className="bg-white p-8 rounded-2xl shadow-xl text-center max-w-md w-full mx-4">
          <Music2 className="w-16 h-16 mx-auto mb-6 text-green-500" />
          <h1 className="text-3xl font-bold mb-4">Spotify Release Dates</h1>
          <p className="text-gray-600 mb-8">
            Connect with Spotify to see the release dates of songs in your playlists.
          </p>
          <button
            onClick={login}
            className="flex items-center justify-center gap-2 w-full bg-green-500 text-white py-3 px-6 rounded-lg font-semibold hover:bg-green-600 transition-colors"
          >
            <LogIn className="w-5 h-5" />
            Connect with Spotify
          </button>
        </div>
      </div>
    );
  }

  const selectedPlaylistData = playlists.find(p => p.id === selectedPlaylist);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <header className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Your Playlists</h1>
          <p className="text-gray-600">Select a playlist to view track release dates</p>
        </header>

        <div className="grid grid-cols-1 md:grid-cols-[300px,1fr] gap-8">
          <div className="grid grid-cols-2 md:grid-cols-1 gap-4 h-fit">
            {playlists.map((playlist) => (
              <PlaylistCard
                key={playlist.id}
                playlist={playlist}
                onClick={() => setSelectedPlaylist(playlist.id)}
                isSelected={selectedPlaylist === playlist.id}
              />
            ))}
          </div>

          <div className="bg-gray-50 p-6 rounded-xl">
            <TrackList 
              tracks={tracks} 
              isLoading={isLoadingTracks}
              onDeleteTracks={handleDeleteTracks}
              playlistOwnerId={selectedPlaylistData?.owner.id ?? ''}
              currentUserId={currentUserId}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;