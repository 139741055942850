import React from 'react';
import { Music2 } from 'lucide-react';
import type { SpotifyPlaylist } from '../types/spotify';

interface PlaylistCardProps {
  playlist: SpotifyPlaylist;
  onClick: () => void;
  isSelected: boolean;
}

export function PlaylistCard({ playlist, onClick, isSelected }: PlaylistCardProps) {
  return (
    <div
      onClick={onClick}
      className={`p-4 rounded-lg cursor-pointer transition-all ${
        isSelected
          ? 'bg-green-500 text-white shadow-lg scale-105'
          : 'bg-white hover:bg-green-50'
      }`}
    >
      {playlist.images && playlist.images[0]?.url ? (
        <img
          src={playlist.images[0].url}
          alt={playlist.name}
          className="w-full h-32 object-cover rounded-md mb-3"
        />
      ) : (
        <div className="w-full h-32 bg-gray-200 rounded-md flex items-center justify-center mb-3">
          <Music2 className="w-12 h-12 text-gray-400" />
        </div>
      )}
      <h3 className="font-semibold truncate">{playlist.name}</h3>
      <p className={`text-sm ${isSelected ? 'text-green-100' : 'text-gray-500'}`}>
        {playlist.tracks.total} tracks
      </p>
    </div>
  );
}