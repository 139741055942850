import React, { useState } from 'react';
import { Calendar, Music, ArrowUpDown, Trash2 } from 'lucide-react';
import type { SpotifyTrack } from '../types/spotify';

interface TrackListProps {
  tracks: SpotifyTrack[];
  isLoading: boolean;
  onDeleteTracks: (trackUris: string[]) => Promise<void>;
  playlistOwnerId: string;
  currentUserId: string;
}

export function TrackList({ 
  tracks, 
  isLoading, 
  onDeleteTracks,
  playlistOwnerId,
  currentUserId 
}: TrackListProps) {
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [selectedTracks, setSelectedTracks] = useState<Set<string>>(new Set());
  const [isDeleting, setIsDeleting] = useState(false);

  const canModifyPlaylist = playlistOwnerId === currentUserId;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500" />
      </div>
    );
  }

  if (tracks.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-64 text-gray-500">
        <Music className="w-12 h-12 mb-2" />
        <p>Select a playlist to view tracks</p>
      </div>
    );
  }

  const sortedTracks = [...tracks].sort((a, b) => {
    const dateA = new Date(a.album.release_date).getTime();
    const dateB = new Date(b.album.release_date).getTime();
    return sortDirection === 'desc' ? dateB - dateA : dateA - dateB;
  });

  const toggleTrackSelection = (trackId: string) => {
    const newSelection = new Set(selectedTracks);
    if (newSelection.has(trackId)) {
      newSelection.delete(trackId);
    } else {
      newSelection.add(trackId);
    }
    setSelectedTracks(newSelection);
  };

  const handleDeleteSelected = async () => {
    if (!canModifyPlaylist || selectedTracks.size === 0 || isDeleting) return;

    setIsDeleting(true);
    try {
      const trackUris = tracks
        .filter(track => selectedTracks.has(track.id))
        .map(track => track.uri);
      await onDeleteTracks(trackUris);
      setSelectedTracks(new Set());
    } catch (error) {
      console.error('Failed to delete tracks:', error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <h2 className="text-xl font-semibold text-gray-900">
            {tracks.length} Tracks
          </h2>
          {selectedTracks.size > 0 && canModifyPlaylist && (
            <button
              onClick={handleDeleteSelected}
              disabled={isDeleting}
              className={`flex items-center gap-2 px-4 py-2 bg-red-500 text-white rounded-lg 
                hover:bg-red-600 transition-colors ${isDeleting ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              <Trash2 className="w-4 h-4" />
              Delete {selectedTracks.size} selected
            </button>
          )}
        </div>
        <button
          onClick={() => setSortDirection(prev => prev === 'desc' ? 'asc' : 'desc')}
          className="flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-sm hover:shadow-md transition-all text-gray-700 hover:text-green-600"
        >
          <ArrowUpDown className="w-4 h-4" />
          Sort by {sortDirection === 'desc' ? 'oldest' : 'newest'}
        </button>
      </div>

      <div className="space-y-4">
        {sortedTracks.map((track) => (
          <div
            key={track.id}
            onClick={() => canModifyPlaylist && toggleTrackSelection(track.id)}
            className={`bg-white p-4 rounded-lg shadow-sm hover:shadow-md transition-all 
              ${canModifyPlaylist ? 'cursor-pointer' : ''} 
              ${selectedTracks.has(track.id) ? 'ring-2 ring-green-500 bg-green-50' : ''}`}
          >
            <div className="flex items-start gap-4">
              {track.album.images && track.album.images[0] && (
                <img
                  src={track.album.images[0].url}
                  alt={track.album.name}
                  className="w-16 h-16 rounded-md object-cover"
                />
              )}
              <div className="flex-1">
                <h3 className="font-semibold text-lg">{track.name}</h3>
                <p className="text-gray-600">{track.artists.map(a => a.name).join(', ')}</p>
                <div className="flex items-center mt-2 text-sm text-gray-500">
                  <Calendar className="w-4 h-4 mr-1" />
                  <time dateTime={track.album.release_date}>
                    {new Date(track.album.release_date).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </time>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}